












































































































































import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL, AWS_S3_BASE_URL } from "../../../../config";
import { authHeader } from "../../../../services/auth";
@Component
export default class Processor extends Vue {
  /* eslint-disable */
  /* eslint-disable */
  public processorList = [];
  public processorId = null;
  public validationStatus = false;
  public message = "";
  public proImage = null;
  public data: any = {};
  public isDisclosureHandledByCompany = false;
  public name: any = {};

  public async getActiveProcessors() {
    try {
      this.callLoader(true, 1);
      const response = await Axios.get(
        `${BASE_API_URL}broker/getActiveSeniorProcessorList`,
        { headers: authHeader() }
        );
      let data = response.data.processorList;

      if (data) {
        this.callLoader(false, null);
        this.processorList = data;
      } else {
        this.callLoader(false, null);
        this.message = "No data found";
      }
    } catch (error) {
      this.callLoader(false, null);
      console.log(error);
    }
  }

  public loadImage(path, i) {
    try {
      setTimeout(function () {
        let dom: any = document.getElementById("imageEl" + i);
        let img = document.createElement("img");
        img.src = path;
        img.id = "img-id" + i;

        if (dom) {
          dom.innerHTML = null;
          dom.appendChild(img);
        }
      }, 10);
    } catch (error) {
      console.log(error);
    }
  }

  public async assignProcessor() {
    try {
      await this.checkValidation();
      if (!this.validationStatus) {
        
        if (this.processorId) {
          this.callLoader(true, 2);
          let response = await Axios.post(
            BASE_API_URL + "broker/allocateLoanToProcessor",
            {
              id: this.processorId,
              loanId: this.$route.query.id,
            },
            {
              headers: authHeader(),
            }
            );
          if (response.status == 200) {
            this.$snotify.success(response.data.msg);
            if (!this.isDisclosureHandledByCompany) {
              await this.sentToProcessorMilestone();
            }
            this.$router.push("/broker-dashboard");
            this.callLoader(false, null);
          }
        } else {
          
          this.callLoader(false, null);
          this.processorId
          ? ""
          : this.$snotify.warning("Please select a processor.");
        }
      }
    } catch (error) {
      console.log(error);
    }
  }



  public async sentToProcessorMilestone() {
    try {
      let response = await Axios.get(
        BASE_API_URL +
        "processor/getDisclosureReceivedInfo/" +
        this.$route.query.id,
        { headers: authHeader() }
        );
      let idsReceivedData = response.data;
      idsReceivedData.isSigned = true;
      let res = await Axios.post(
        BASE_API_URL + "processor/saveDisclosureDetails",
        {
          loanId: this.$route.query.id,
          initialDisclosureReceived: idsReceivedData,
          taskId: 2,
          userType: this.$store.state.sessionObj.userType,
          isComplete: false,
        },
        {
          headers: authHeader(),
        }
        );
      if (res.status == 201) {
        this.$snotify.success("Disclosure has been signed.");
      }
    } catch (error) {
      this.callLoader(false, null);
      console.log(error);
    }
  }

  public async checkValidation() {
    try {
      let res = await Axios.get(
        BASE_API_URL +
        "broker/checkApplicationFormValidation/" +
        this.$route.query.id,
        {
          headers: authHeader(),
        }
        );
      this.validationStatus = res.data.validationStatus;
      
      this.message = res.data.message ;
      
      if (this.message) this.$snotify.warning(this.message);
    } catch (error) {
      console.log(error);
    }
  }

  public async getLoanStatus() {
    let res = await Axios.get(
      BASE_API_URL + "common/getLoanStatus/" + this.$route.query.id,
      {
        headers: authHeader(),
      }
      );
    this.isDisclosureHandledByCompany = res.data.idsHandledByTMPCO;
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }

  public async getprofileImage(data) {
    this.callLoader(true,1)
    try {
      const processordata = {
        userId: data._id,
      };
      const response = await Axios.post(
        `${BASE_API_URL}common/my-account/getprofileImage`,
        processordata,
        { headers: authHeader() }
        );

      if (response.data.profileImageData.profileImage) {
        this.proImage = response.data.profileImageData.profileImage;
        this.name = response.data.profileImageData.name;
      }
      

    } catch (error) {
      console.log(error);
    }
    this.callLoader(false,null)
  }

  async mounted() {
    await this.getActiveProcessors();
    await this.getLoanStatus();
  }
}
